
import Desc from '../desc/Desc'
import InformationSection from '../info/Info'
import Reviews from '../reviews/reviews'
import useDocumentTitle from '../../hooks/title'
import Carouselcomp from '../carosuel/Carousel'
import GoogleOp from '../GoogleOp/googleop'
import SocialMediaIcons from '../socials/Socials'
import Partners from '../partners/Partners'

function Home (){
    useDocumentTitle("Adeeb Clinic - Profesjonalne Usługi Stomatologiczne")
    return(
        <div>
           <Carouselcomp/>
           <SocialMediaIcons/>
           <Desc/>
           <GoogleOp/>
           <Reviews/>
           <InformationSection/>
           <Partners/>
        </div>
    )

}

export default Home