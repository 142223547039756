import useDocumentTitle from "../../hooks/title"

const NotFound = () =>{
    useDocumentTitle(404)
    return(
        <div>
            <h1>404</h1>
            <p>Not found</p>
        </div>
    )
}

export default NotFound