
function Desc() {
    return (
        <div className="container mt-5">
          <h1 className="display-4 text-center stomatologia">Stomatologia</h1>
          <h1 className="display-4 text-center adeeb">Adeeb Clinic</h1>
          <p className="text-center opis">
          Wszyscy chcemy mieć piękne i zdrowe zęby, jednak czasami się zdarza, że pacjent nie jest zadowolony ze swojego uśmiechu. W Adeeb Clinic podchodzimy do leczenia kompleksowo.
Dbając o naszych Pacjentów oraz najwyższe standardy świadczonych usług ciągle inwestujemy 
w innowacyjne rozwiązania technologiczne. W Adeeb Clinic znajdują się nowocześnie wyposażone gabinety. Nasz zespół tworzą specjaliści stomatologii w pełnym zakresie usług (stomatologii zachowawczej, estetycznej, chirurgii stomatologicznej, ortodoncji, protetyki). Wszystkie usługi wykonujemy z jak największą starannością 
i dokładnością oraz wiedzą i sztuką lekarską. Leczymy w oparciu o światowej klasy materiały, które gwarantują najwyższą jakość, precyzję i estetykę. Cały zespół na bieżąco poszerza zdobytą wiedzą 
i doświadczenie na wielu kursach, także zagranicznych, otrzymując wiele dyplomów i certyfikatów. Wszyscy lekarze udoskonalają swoje umiejętności na szkoleniach. Dzięki temu oferujemy kompleksową opiekę stomatologiczną. Posiadamy podpisany kontrakt 
z Narodowym Funduszem Zdrowia. Wykonujemy usługi dla pacjentów także prywatnie.
          </p>
        </div>
      );
  }
  
  export default Desc;
  