import useDocumentTitle from "../../hooks/title"

const Radiologic = () =>{
    useDocumentTitle("Ochrona radiologiczna")
    return(
        <div className="container my-5">
        <h1 className="text-center galeria">Ochrona radiologiczna - akty prawne</h1>
        <p className="text-center">
        1. Ustawa z dnia 29 listopada 2000 – Prawo Atomowe, Obwieszczenie Marszałka Sejmu Rzeczypospolitej Polskiej z dnia 11 maj 2023 w sprawie ogłoszenia jednolitego tekstu ustawy - Prawo atomowe (Dz.U. 2023 poz. 1173) z późniejszymi zmianami </p>

        <p className="text-center"> 2. Rozporządzenie Ministra Zdrowia z dnia 21 sierpnia 2006 w sprawie szczegółowych warunków bezpiecznej pracy z urządzeniami radiologicznymi (Dz.U. 2006.180.1325) </p>

        <p className="text-center">3. Obwieszczenie Prezesa Rady Ministrów z dnia 29 marca 2022 r. w sprawie ogłoszenia jednolitego tekstu rozporządzenia Rady Ministrów w sprawie szczegółowych warunków bezpiecznej pracy ze źródłami promieniowania jonizującego Dz.U. 2022 poz. 967 </p>

        <p className="text-center"> 4. Rozporządzenie Ministra Zdrowia z dnia 8 czerwca 2020 w sprawie zakresu informacji zawartych w Centralnym Rejestrze Danych o Ekspozycjach Medycznych (Dz.U. 2020.1051) </p>

<p className="text-center"> 5.Rozporządzenie Ministra Zdrowia z dnia 6 marca 2020 w sprawie szkoleń w dziedzinie ochrony radiologicznej pacjenta (Dz.U. 2020.390) </p>

<p className="text-center"> 6.Rozporządzenie Ministra Zdrowia z dnia 18 listopada 2020 zmieniające rozporządzenie w sprawie szkoleń w dziedzinie ochrony radiologicznej pacjenta (Dz.U. 2020.2044) </p>

<p className="text-center"> 7.Rozporządzenie Rady Ministrów z dnia 30 listopada 2020 w sprawie ochrony przed promieniowaniem jonizującym pracowników zewnętrznych narażonych podczas pracy na terenie kontrolowanym lub nadzorowanym (Dz.U. 2020.2313) </p>

<p className="text-center"> 8.Rozporządzenie Rady Ministrów z dnia 30 listopada 2020 zmieniające rozporządzenie w sprawie podstawowych wymagań dotyczących terenów kontrolowanych i nadzorowanych.(Dz.U. 2020.2303) </p>

<p className="text-center"> 9.Rozporządzenie Rady Ministrów z dnia 30 listopada 2020 zmieniające rozporządzenie w sprawie 
szczegółowych warunków bezpiecznej pracy ze źródłami promieniowania jonizującego (Dz.U. 2020.2300) </p>

<p className="text-center"> 10.Rozporządzenie Rady Ministrów z dnia 25 maja 2021 w sprawie wymagań dotyczących rejestracji dawek indywidualnych (Dz.U. 2021.1053)</p>

<p className="text-center"> 11.Rozporządzenie Rady Ministrów z dnia 25 maja 2021 w sprawie zakresu analizy zagrożeń wynikających 
z działalności związanej z narażeniem na promieniowanie jonizujące oraz formy przedstawiania wniosków 
z analizy zagrożeń (Dz.U. 2021.1059) </p>

<p className="text-center"> 12.Rozporządzenie Rady Ministrów z dnia 25 maja 2021w sprawie planów postępowania awaryjnego w przypadku zdarzeń radiacyjnych (Dz.U. 2021.1086) </p>

<p className="text-center"> 13. Rozporządzenie Rady Ministrów z dnia 30 sierpnia 2021 w sprawie dokumentów wymaganych przy składaniu wniosku o wydanie zezwolenia na wykonywanie działalności związanej z narażeniem na działanie promieniowania jonizującego albo przy zgłoszeniu wykonywania tej działalności (Dz.U. 2021.1667) </p>

<p className="text-center"> 14.Rozporządzenie Ministra Zdrowia z dnia 13 września 2021 w sprawie minimalnych wymagań dla jednostek ochrony zdrowia prowadzących działalność związaną z narażeniem w celach medycznych, polegającą na udzielaniu świadczeń zdrowotnych z zakresu rentgenodiagnostyki, radiologii zabiegowej lub diagnostyki związanej z podawaniem pacjentom produktów radiofarmaceutycznych (Dz.U. 2021.1725) </p>

<p className="text-center"> 15.Rozporządzenie Ministra Zdrowia z dnia 14 października 2021 w sprawie nadawania uprawnień inspektora ochrony radiologicznej sprawującego wewnętrzny nadzór nad przestrzeganiem wymagań ochrony radiologicznej w jednostkach ochrony zdrowia (Dz.U. 2021.1908) </p>

<p className="text-center"> 16.Rozporządzenie Ministra Zdrowia z dnia 18 października 2021 w sprawie formy i szczegółowego zakresu wzorcowych medycznych procedur radiologicznych dla standardowych ekspozycji medycznych oraz szczegółowych medycznych procedur radiologicznych (Dz.U. 2021.1908; Dz.U. 2023.1556) </p>

<p className="text-center"> 17.Rozporządzenie Ministra Zdrowia z dnia 19 października 2021 w sprawie informacji zawartych w Krajowej Bazie Urządzeń Radiologicznych (Dz.U. 2021.1959)</p>

<p className="text-center"> 18.Obwieszczenie Prezesa Rady Ministrów z dnia 21 marca 2022 r. w sprawie ogłoszenia jednolitego tekstu rozporządzenia Rady Ministrów w sprawie podstawowych wymagań dotyczących terenów kontrolowanych 
i nadzorowanych Dz.U. 2022 poz. 722 </p>

<p className="text-center"> 19.Obwieszczenie Ministra Zdrowia z dnia 4 kwietnia 2022 r. w sprawie ogłoszenia jednolitego tekstu rozporządzenia Ministra Zdrowia w sprawie szkoleń w dziedzinie ochrony radiologicznej pacjenta Dz.U. 2022 poz. 851 </p>

<p className="text-center"> 20.Rozporządzenie Ministra Zdrowia z dnia 13 grudnia 2022 r. w sprawie kategorii oraz kryteriów kwalifikowania ekspozycji niezamierzonych i narażeń przypadkowych, działań, które należy podjąć w jednostce ochrony zdrowia po ich wystąpieniu, a także zakresu informacji objętych Centralnym Rejestrem Ekspozycji Niezamierzonych i Narażeń Przypadkowych(Dz.U. 2022 poz. 2700) </p>
<p className="text-center"> 21.Rozporządzenie Ministra Zdrowia z dnia 6 grudnia 2022 r. w sprawie szczegółowego zakresu audytów klinicznych wewnętrznych oraz audytów klinicznych zewnętrznych oraz wzoru raportów z ich przeprowadzenia (Dz.U. 2022 poz. 2683) </p>
<p className="text-center"> 22.Rozporządzenie Ministra Zdrowia z dnia 12 grudnia 2022 r. w sprawie testów eksploatacyjnych urządzeń radiologicznych i urządzeń pomocniczych (Dz.U. 2022 poz. 2759) </p>
<p className="text-center"> 23.Rozporządzenie Ministra Zdrowia z dnia 11 stycznia 2023 r. w sprawie warunków bezpiecznego stosowania promieniowania jonizującego dla wszystkich rodzajów ekspozycji medycznej (Dz.U. 2023 poz. 195)</p>
<p className="text-center"> 24.Rozporządzenie Ministra Zdrowia z dnia 8 marca 2023 r. w sprawie szczególnej ochrony niektórych kategorii osób w związku z ekspozycją medyczną w badaniach diagnostycznych, zabiegach i leczeniu 
(Dz.U. 2023 poz. 576) </p>
<p className="text-center"> 25.Obwieszczenie Ministra Zdrowia z dnia 10 listopada 2015 w sprawie ogłoszenia wykazu wzorcowych procedur radiologicznych z zakresu radiologii – diagnostyki obrazowej i radiologii zabiegowej (Dz.U. MZ 2015.78) </p>

<p className="text-center"> PRAWO EUROPEJSKIE </p>
<p className="text-center"> 1.Dyrektywa Rady Unii Europejskiej 2013/51/EURATOM z dnia 22 października 2013 określająca wymogi dotyczące ochrony zdrowia ludności w doniesieniu do substancji promieniotwórczych w wodzie przeznaczonej do spożycia przez ludzi (Dz.U. WE 2013, L 296/12) </p>

<p className="text-center"> 2.Dyrektywa rady Unii Europejskiej 2013/59/EURATOM z dnia 5 grudnia 2013 ustanawiająca podstawowe normy bezpieczeństwa w celu ochrony przed zagrożeniami wynikającymi z narażenia na działanie promieniowania jonizującego oraz uchylająca dyrektywy 89/618/Euratom, 90/641/Euratom, 96/29/Euratom, 97/43/Euratom i 2003/122/Euratom (Dz.U. UE.L.2014.13.1) </p>

<p className="text-center"> 3.Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) Dz.U. UE.L.2016.119.1. </p>

<p className="text-center"> PRZEPISY DODATKOWE </p>
<p className="text-center"> 1. Obwieszczenie Marszałka Sejmu Rzeczypospolitej Polskiej z dnia 9 czerwca 2022 r. w sprawie ogłoszenia jednolitego tekstu ustawy –Kodeks pracy(Dz.U. 2022 poz. 1510) </p>

<p className="text-center"> 2.Rozporządzenie Ministra Zdrowia z dnia 24 lipca 2012 w sprawie substancji chemicznych, ich mieszanin, czynników lub procesów technologicznych o działaniu rakotwórczym lub mutagennym w środowisku pracy-tekst jednolity (Dz.U. 2012.890) </p>

<p className="text-center"> 3.Rozporządzenie Ministra Zdrowia z dnia 13 września 2011 w sprawie zadań służby medycyny pracy, których wykonywanie przez osoby niebędące lekarzami wymaga posiadania dodatkowych kwalifikacji (Dz.U. 2011.206.1223 </p>

    <p className="text-center"> 4.Rozporządzenie Ministra Zdrowia z dnia 22 kwietnia 2005 w sprawie szkodliwych czynników biologicznych dla zdrowia w środowisku pracy oraz ochrony zdrowia pracowników zawodowo narażonych na te czynniki (Dz.U. 2005.81.716,Dz.U. 2008.48.288 oraz Dz.U. 2020.2234) </p>

    <p className="text-center"> 5.Rozporządzenie Ministra Zdrowia z dnia 30 grudnia 2004 w sprawie bezpieczeństwa i higieny pracy związanej z występowaniem w miejscu pracy czynników chemicznychDz.U. 2005.11.86objęte tekstem jednolitym (Dz.U. 2016.1488) </p>

    <p className="text-center"> 6. Rozporządzenie Ministra Zdrowia z dnia 3 marca 2006 w sprawie sposobu i trybu wydawania zaświadczenia lekarskiego stwierdzającego przeciwwskazania zdrowotne do wykonywania dotychczasowej pracy przez pracownicę w ciąży lub karmiącą dziecko piersią (Dz.U. 2006.42.292) </p>

    <p className="text-center"> 7. Rozporządzenie Rady Ministrów z dnia 3 kwietnia 2017 w sprawie wykazu prac uciążliwych, niebezpiecznych lub szkodliwych dla zdrowia kobiet w ciąży i kobiet karmiących dziecko piersią  
(Dz.U. 2017.796) </p>

<p className="text-center"> 8.Ustawa o wyrobach medycznych z dnia 7 kwietnia 2022 (Dz.U. 2022.974) </p>

<p className="text-center"> Data opracowania dokumentu: 11/07/ 2023 rok </p>
      </div>
    )
}

export default Radiologic