import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import clock from './clock.png';
import localization from './localization.png';
import gpay from './gpay.png'
import vis from './vis.png'
import blik from "./blik.svg.webp"
import jcb from './jcb.jpeg'
import mastercard from './mastercard-logo-02-753x424-1.png'
import applepay from './apple-pay-logo.0f9a7995.png'
import firstdata from './First-Data-Polcard.jpg'
import gotowka from './Beznazwy-1.png'
import gotowka2 from './gotowka.png'

import phone1 from './call2.png';

function Footer() {
    return (
        <div className="container-fluid footer-border text-center mt-5">
            <footer className="bg-light align-items-center">
                <div className="container p-4">
                    <div className="row">
                        {/* Contact Section */}
                        <div className="col-lg-4 col-md-6">
                            <div className="d-flex align-items-center"> {/* Added align-items-center */}
                                <div className="flex-shrink-0">
                                <a href="tel:322600121">
                                <img src={phone1} alt="Phone" style={{ width: '44px' }} />
                                </a>
                                </div>
                                <div className="flex-grow-1">
                                    <h5 className="text-uppercase footer-title">Kontakt</h5>
                                    <p className="text-dark footer-info">Tel: 32 2600121</p>
                                </div>
                            </div>
                        </div>

                        {/* Working Hours Section */}
                        <div className="col-lg-4 col-md-6">
                            <div className="d-flex align-items-center"> {/* Added align-items-center */}
                                <div className="flex-shrink-0">
                                    <img src={clock} alt="Clock" style={{ width: '44px' }} />
                                </div>
                                <div className="flex-grow-1">
                                    <h5 className="text-uppercase footer-title">Godziny pracy</h5>
                                    <p className="text-dark footer-info">Poniedziałek-Piątek: 08:00-20:00</p>
                                </div>
                            </div>
                        </div>

                        {/* Address Section */}
                        <div className="col-lg-4 col-md-6">
                            <div className="d-flex align-items-center"> {/* Added align-items-center */}
                                <div className="flex-shrink-0">
                                    
                                    <img src={localization} alt="Localization" style={{ width: '44px' }} />
                                </div>
                                <div className="flex-grow-1">
                                    <h5 className="text-uppercase footer-title">Adres</h5>
                                    <p className="text-dark footer-info">ul. Legionów Polskich 73/1, 41-300 Dąbrowa Górnicza</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </footer>
        </div>
    );
}

export default Footer;


