import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

const reviews = [
    { id: 1, author: "Roksana Kawczyńska", content: "Jestem bardzo zadowolona, byłam na usunięciu ósemki bardzo się bałam, ale pracują tu tak wspaniali ludzi że cały czas mnie uspokajali, i wyrwanie zajęło doktorowi moze z minutę, nawet nie wiedziałam z już doktor wyrwał zęba . Naprawdę z całego serca polecam tą klinikę. Jestem w szoku, że pracują tu tak wspaniali ludzie ❤️❤️❤️", rating: 5 },
    { id: 2, author: "Krzysztof Dycha", content: "Byłem po raz pierwszy w tej klinice jestem bardzo zadowolony pełny profesjonalizm pani w recepcji bardzo miła i kompetentna cała kadra zespołu medycznego super a chirurg pełen profesjonalizm polecam wszystkim i bardzo dziękuję", rating: 5 },
    { id: 3, author: "Basia Nowak", content: "Z całego serca polecam dr Wahab Adeeb każdemu kto ma problem z zębami i potrzebuje chirurgicznego usunięcia zęba. Nie znam bardziej profesjonalnego chirurga szczękowego jak Pan Doktor Wahab, jest cudownym specjalistą i wspaniałym empatycznym człowiekiem, właśnie w czwartek miałam usuwanego zęba a jestem trudnym przypadkiem i Pan dr zrobił to perfekcyjnie. Nikomu innemu bym nie zaufała w kwestii chirurgii szczękowej. Do tego cudowna obsługa Pań w rejestracji i całego zespołu. Każdy kto potrzebuje natychmiastowej ulgi w bólu jest przyjęty. Kasia S.", rating: 5 },
    { id: 4, author: "Artur Wers", content: "Najlepsza klinika dentystyczna , bardzo miły personel.Usuwanie zębów naprawdę delikatnie i bezbolesne .Polecam klinikę w 100%", rating: 5},
    { id: 5, author: "Henryka Migoń", content: "Polecam wszystkim którzy mają strach przed dentystą. Obsługa wspaniała, pełen profesjonalizm, bardzo mili ludzie, uśmiechnięci i bardzo pomocni. Jeszcze raz polecam z całego serca i dziękuję za cierpliwość i wyrozumiałość", rating: 5 },
    { id: 6, author: "Basia A", content: "Profesjonalna obsługa. Pani Sandra rewelacyjna. W moim przypadku bezboleśnie zrobione dwa zęby. Reszta personelu również profesjonalna. Wybieram się ponownie.", rating: 5},


  ];


  const Reviews = () => {
    // Podziel recenzje na grupy po trzy
    const groupedReviews = [];
    for (let i = 0; i < reviews.length; i += 3) {
      groupedReviews.push(reviews.slice(i, i + 3));
    }
  
    return (
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators={true}
        autoPlay={true}
        showArrows={true}
        infiniteLoop={true}
      >
        {groupedReviews.map((group, index) => (
          <div key={index} className="container p-5">
            <div className="row">
              {group.map(review => (
                <div key={review.id} className="col-md-4 p-3">
                  <div className="card kard">
                    <div className="card-body">
                      <h5 className="card-title">{review.author}</h5>
                      <div className="mb-2 stars">
                        {'★'.repeat(review.rating)}
                        {'☆'.repeat(5 - review.rating)}
                      </div>
                      <p className="card-text">{review.content}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </Carousel>
    );
  };
  
  export default Reviews;