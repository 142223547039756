import image1 from '../home/groupfoto.png'
import image2 from './slajd 2.png'
import image3 from './slajd 3.png'
import image4 from './test77.jpeg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

function Carouselcomp() {
    return (
        <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        autoPlay={true}
        infiniteLoop={true}
        >
            <div>
                <img className="img-fluid w-60" src={image4} alt="Image 1" />
            </div>
            <div>
                <img className="img-fluid w-100" src={image2} alt="Gallery" />
            </div>
            <div>
                <img className="img-fluid w-100" src={image3} alt="Gallery" />
            </div>
            
        </Carousel>
    );
}

export default Carouselcomp;
