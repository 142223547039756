import React from 'react';
import { FaFacebook, FaInstagram } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';

const SocialMediaIcons = () => {
  return (
    <div className="container position-fixed top-5 end-0 p-2" style={{ zIndex: 1000 }}> {/* Ustawienie z-index */}
      <div className="d-flex flex-column align-items-end">
      <a href="https://www.facebook.com/adeebclinic" target="_blank" rel="noopener noreferrer" className="mb-2 icons">
          <FaFacebook color="gold" size={30} />
        </a>
        <a href="https://www.instagram.com/adeebclinic" target="_blank" rel="noopener noreferrer" className='icons'>
          <FaInstagram color="gold" size={30} />
        </a>
      </div>
    </div>
  );
};

export default SocialMediaIcons;
