import Usluga1 from './Usługi 1.png'
import Usluga2 from './Usługi 2.png'
import Usluga3 from './Usługi 3.png'
import Usluga4 from './Usługi 4.png'
import Usluga5 from './Usługi 5.png'
import Usluga6 from './Usługi 6.png'
import Usluga7 from './Usługi 7.png'
import Usluga8 from './Usługi 8.png'
import { Link } from 'react-router-dom'
import useDocumentTitle from '../../hooks/title'



const ServiceItem = ({ image, title, descriptions }) => {
  return (
    <div className="row bg-light mt-5">
      <div className="col-md-4 d-flex align-items-center justify-content-center">
        <img src={image} style={{ width: '50%' }} alt="Icon" />
      </div>
      <div className="col-md-8">
        <div className="service-item p-3">
          <h5 className="service-title mt-5 usluga">{title}</h5>
          <ul className="list-styled mt-5">
            {descriptions.map((description, index) => (
              <li key={index}>{description}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const ServicesComponent = () => {
  useDocumentTitle("Usługi")
  const services = [
    {
      image: Usluga1,
      title: 'CHIRURGIA STOMATOLOGICZNA',
      descriptions: [
        'implantologia',
        'usuwanie zatrzymanych zębów (np. zęby mądrości i inne)',
        "ekstrakcja pozostałości korzeni zębów",
        "zabiegi resekcji wierzchołka korzeni zębów",
        "plastyka połączenia zatok",
        "plastyka wędzidełek",
        "plastyka przedsionka jamy ustne",
        "usuwanie torbieli zębopochodnych",
        "nacięcie ropnia",
        "kiretaż kieszonek dziąsłowych otwarty i zamknięty",
        "Gingiwoosteoplastyka (chirurgiczne przygotowanie wyrostka zębodołowego do protezy)",
        "nowa metoda uzupełnień ubytków w kości po zabiegach materiałami kościotwórczymi"
       
      ],
    },
    {
      image: Usluga2,
      title: 'ORTODONCJA',
      descriptions: [
        'Leczenie wad zgryzu dorosłych i dzieci różnymi metodami:',
        '   aparaty nakładkowe niewidoczne INVISALIGN oraz ORTHERO',
        '   aparaty stałe',
        'aparaty ruchome',
        'leczenie stawów skroniowo żuchwowych'
       
      ],
    },
    {
      image: Usluga3,
      title: 'PERIODONTOLOGIA',
      descriptions: [
        'leczenie chorób błony śluzowej',
        'szynowanie rozchwianych zębów',
       
      ],
    }, {
      image: Usluga4,
      title: 'PROTETYKA',
      descriptions: [
        'korony i mosty (najnowocześniejszą metodą - cyrkonowe bez metalu, a także porcelanowe, kompozytowe,    lane stalowe, metalowe, tymczasowe akrylowe)',
        'protezy natychmiastowe (protezy zakładane w dniu usunięcia zębów)',
        'protezy całkowite i częściowe',
        'protezy acetylowe (elastyczne bez drutu)',
        'protezy szkieletowe',
        'protezy ekspresowe',
        'naprawa protez',
        'podścielenie protez',
        'licówki'
       
      ],
    },
    {
      image: Usluga5,
      title: 'STOMATOLOGIA ZACHOWAWCZA',
      descriptions: [
        'leczenie ubytków próchnicowych',
        'odbudowa zniszczonych zębów',
        'endodoncja (leczenie kanałowe)'
       
      ],
    }, 
    {
      image: Usluga6,
      title: 'PROFILAKTYKA I STOMATOLOGIA ESTETYCZNA',
      descriptions: [
        'czyszczenie osadu',
        'usunięcie kamienia',
        'piaskowanie, polerowanie',
        'zabezpieczenie zębów lakierem fluorem',
        'wybielanie zębów lampą Crystal',
        'wybielanie zębów po leczeniu kanałowym',
        'licówki, korony',
        'impregnacja zębów',
        'lakowanie zębów',
       'Flow Injection Technique - nowoczesne metody poprawy uzębienia'

       
      ],
    },
    {
      image: Usluga7,
      title: 'PRACOWNIA RENTGENOWSKA',
      descriptions: [
        'zdjęcia zębów pojedyncze cyfrowe',
        'zdjęcia pantomograficzne cyfrowe',
      ],
    },
    {
      image: Usluga8,
      title: 'MEDYCYNA ESTETYCZNA',
      descriptions: [
        'zabiegi medycyny estetycznej z wykorzystaniem botoxu i kwasu hialuronowego',
      ],
    }, 
  ];

  return (
    <div className="container-fluid p-5">
      <h2 className="text-center galeria">Nasze Usługi</h2>
      {services.map((service, index) => (
        <ServiceItem
          key={index}
          image={service.image}
          title={service.title}
          descriptions={service.descriptions}
        />
      ))}
       <div className="text-center mt-5">
        <Link to="/ochrona-radiologiczna" className="btn btn-light btn-lg">
          Ochrona Radiologiczna
        </Link>
      </div>
    </div>
  );
};

export default ServicesComponent;