const TeamMember = ({ name, image, description }) => {
    return (
      <div className="row align-items-center mb-5 p-5">
        <div className="col-md-6 col-sm-12 d-flex justify-content-center"> {/* Dla małych ekranów centrujemy obrazek */}
          <img 
            src={image} 
            alt={`Zdjęcie ${name}`} 
            className="team-foto" 
            style={{ width: '65%', height: '65%' }} 
          />
        </div>
        <div className="col-md-6 col-sm-12 mt-5">
          <h5 className="mt-0 usluga">{name}</h5>
          <p className="text-justify">{description}</p>
        </div>
      </div>
    );
  };
  
  export default TeamMember;
  
  