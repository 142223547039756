
import TeamMember from './teammember';
import zespol1 from './Zespół 1.png'
import zespol2 from './Zespół 2.png'
import zespol3 from './Zespół 3.png'
import zespol4 from './Zespół 4.png'
import zespol5 from './Zespół 5.png'
import zespol6 from './Zespół 6.png'
import zespol7 from './Zespół 7.png'
import zespol8 from './Zespół 8.png'
import useDocumentTitle from '../../hooks/title';





const TeamSection = () => {
    useDocumentTitle("Zespół")
  const teamMembers = [
    {
      name: 'Dr nauk med. lekarz dentysta chirurg stomatolog - Wahab Adeeb',
      image: zespol1,
      description:`
      Ukończył studia na Śląskim Uniwersytecie Medycznym na wydziale lekarsko-dentystycznym, uzyskał dyplom doktora nauk medycznych oraz z chirurgii stomatologicznej. Jest także Inspektorem Ochrony Radiologicznej. Bierze czynny udział w licznych polskich i międzynarodowych sympozjach naukowych, otrzymując wiele dyplomów i certyfikatów - obecnie posiada ich już ponad 100. Ciągle pogłębia swoją wiedzę na szkoleniach i konferencjach chirurgów stomatologów, co doceniają zarówno pacjenci, jak i koledzy lekarze. Doktor Wahab jest znany jako godny zaufania fachowiec, co potwierdzają liczne pozytywne opinie na stronach internetowych takich jak znanylekarz.pl czy rankinglekarzy.pl. Został wyróżniony w przeprowadzanym rankingu jako najlepszy lekarz wśród stomatologów.
    
      Jest właścicielem Specjalistycznej Przychodni Stomatologicznej "Happy Dent" oraz „Stomatologia Adeeb Clinic”. Codziennie wykonuje wiele skomplikowanych zabiegów chirurgii stomatologicznej oraz stomatologii estetycznej. Zajmuje się kompleksową chirurgią stomatologiczną, również w znieczuleniu ogólnym (narkoza).
    
      Profesjonalnie wykonuje specjalistyczne zabiegi, łącząc zdobyte umiejętności z innowacyjnymi metodami leczenia i cyfrową technologią, dążąc do perfekcyjnego rezultatu w każdym przypadku klinicznym. Szkoli młodych dentystów i dentystów stażystów, jest autorem wielu publikacji naukowych oraz książek wydawanych zarówno w Polsce, jak i za granicą. Jego wieloletnie doświadczenie oraz praktyka zawodowa w różnych klinikach stomatologicznych oraz na chirurgii szczękowo-twarzowej zyskały uznanie wśród pacjentów oraz lekarzy.
    `
    
    // Użycie zmiennej description w komponencie JSX
    
    },
    {
      name: 'Dr nauk med. lekarz dentysta, Master ortodoncji i ortopedii szczękowej - Sandra Adeeb',
      image: zespol2,
      description: `  W języku angielskim ukończyła studia na Śląskim Uniwersytecie Medycznym na wydziale lekarsko – dentystycznym. W 2022 roku uzyskała stopień doktora nauk medycznych na Uniwersytecie Medycznym w Łodzi w zakresie ortodoncji. Ukończyła studia podyplomowe Master w Ortodoncji i Ortopedii Szczękowej Athenea Dental Institute, Uniwersytet San Jorge w Barcelonie otrzymując tytuł Ortodonty oraz uzyskując uprawnienia do świadczenia usług z zakresu ortodoncji. Członek brytyjskiej izby lekarskiej General Medical Council. W 2020 roku podjęła roczny staż w NZOZ „Happy Dent”, gdzie rozpoczęła swoją pierwszą pracę. Pracowała także w Buckingham Gate Orthodontics w Londynie, Wielka Brytania jako lekarz dentysta zajmujący się ortodoncją. Aktualnie razem z bliskimi : siostrą dr n. med lekarzem dentystą Sają Adeeb i ojcem dr n. med chirurgiem stomatologii Wahab Adeeb, prowadzi rodzinną klinikę stomatologiczną „Adeeb Clinic” w Dąbrowie Górniczej. Doktor Sandra Adeeb, poprzez swój autorytet w dziedzinie innowacyjności w ortodoncji, jest Ambasadorką w zapobieganiu i leczeniu wad zgryzu oraz estetyki stomatologicznej. Jest autorką kilku książek i publikacji naukowych medycznych o tematyce stomatologicznej w Polsce oraz zagranicą. Członek Koła Naukowego Studenckiego Towarzystwa Naukowego w Katedrze i Zakładzie Anatomii Opisowej i Topograficznej oraz Koła Naukowego w Zakładzie Mikrobiologii w Zabrzu. Uczestnik kilkudziesięciu konferencji naukowych i szkoleń praktycznych. W codziennej praktyce zajmuje się leczeniem wad zgryzu oraz stomatologią zachowawczą. Dzięki biegłej znajomości języka angielskiego prowadzi leczenie pacjentów z całego świata.`,
    },
    {
        name: 'Dr nauk med. lekarz dentysta - Saja Adeeb medycyna estetyczna',
        image: zespol3,
        description: `Ukończyła studia anglojęzyczne na Śląskim Uniwersytecie Medycznym 
        na wydziale lekarsko – dentystycznym.
        W 2022 roku uzyskała stopień doktora nauk medycznych na Uniwersytecie Medycznym w Łodzi w zakresie stomatologii estetycznej. Członek brytyjskiej izby lekarskiej General Medical Council. 
        Ukończyła studia podyplomowe z medycyny estetycznej pozwalającej usunąć 
        lub poprawić niedoskonałości o różnym podłożu.
        W 2020 roku podjęła roczny staż w NZOZ „Happy Dent”, 
        gdzie rozpoczęła swoją pierwszą pracę.
        Razem z bliskimi : ojcem dr n. med chirurgiem stomatologii Wahab Adeeb oraz siostrą dr n. med lekarzem dentystą Sandrą Adeeb, prowadzi rodzinną klinikę stomatologiczną „Adeeb Clinic” w Dąbrowie Górniczej.
        Doktor Saja Adeeb interesuje się i zajmuje odbudową estetyczną zębów oraz stale się w tych dziedzinach dokształca.
        Wykonuje zabiegi medycyny estetycznej pozwalające 
        dłużej zachować młodość i urodę.
        Jest autorką kilku książek i publikacji naukowych medycznych o tematyce stomatologicznej w Polsce oraz zagranicą. Członek Koła Naukowego Studenckiego Towarzystwa Naukowego w Katedrze i Zakładzie Anatomii Opisowej i Topograficznej oraz Koła Naukowego 
        w Zakładzie Mikrobiologii w Zabrzu.
        Uczestnik kilkudziesięciu konferencji naukowych i szkoleń praktycznych.
        Nieustannie szkoli się w zakresie stomatologii, ucząc się od najlepszych specjalistów i stale poszerzając zakres wykonywanych zabiegów.
        Dzięki biegłej znajomości języka angielskiego prowadzi leczenie 
        pacjentów z całego świata.`,
      },
      {
        name: 'Amin Adeeb - Asystent dentystyczny ',
        image: zespol4,
        description: `Studia na wydziale lekarsko – dentystycznym na Wrocławskim Uniwersytecie Medycznym to wymarzony kierunek Amina Adeeb. Obecnie student trzeciego roku anglojęzycznych studiów stomatologicznych.
        Specyfika tego zawodu jest Aminowi bardzo dobrze znana, gdyż jego tata jest lekarzem dentystą ze specjalizacją chirurgii stomatologicznej oraz posiada tytuł doktora nauk medycznych oraz dwie siostry także ukończyły studia na tym samym kierunku. Od dziecka otacza się w środowisku stomatologów. Dysponuje różnymi informacjami oraz ciekawostkami związanymi z zawodem stomatologa i jest tą specjalnością bardzo zafascynowany. Asystuje przy różnych zabiegach chirurgicznych, także tych skomplikowanych. Bierze udział w zabiegach higienizacji jamy ustnej.
        Przez nauczycieli jest ceniony za ambicje oraz sumienność w wykonywaniu zadań. Chętnie uczestniczy w wielu zajęciach nieobowiązkowych oraz szkoleniach poszerzając zdobywaną wiedzę i podnosząc swoje umiejętności.
        Jest koleżeński i chętnie udziela się w zadaniach zespołowych. Przywiązuje dużą uwagę na rzetelność i kompetentną wiedzę na temat podjętego wyzwania.
        Interesuje się chirurgią stomatologiczną i złożonymi przypadkami z tej dziedziny.
        `,
      },
      {
        name: 'ARTSIOM - Lekarz Dentysta, zajmuje się stomatologią zachowawczą i protetyką.',
        image: zespol5,
        description: `Głównym obszarem zainteresowań zawodowych Doktora są estetyczne odbudowy protetyczne oraz stomatologia zachowawcza. Przy wykonywaniu uzupełnień protetycznych zwraca szczególną uwagę na ich efekt estetyczny, ale także na funkcjonalność i trwałość. Głównym celem jest rehabilitacja jamy ustnej z wykorzystaniem nowoczesnych i minimalnie inwazyjnych technik leczenia. Zależy mu, by licówki, korony czy inne uzupełnienia były wykonane w stabilnych warunkach zgryzowych i dzięki temu służyły pacjentom przez długie lata Podczas leczenia stara się dbać o komfort pacjentów i przyjazną atmosferę w gabinecie. Przykłada dużą wagę do wspólnego ustalenia i omówienia kompleksowego planu leczenia.
        `,
      },
      {
        name: 'NATALIA - Lekarz Dentysta z wieloletnim doświadczeniem w endodoncji i estetyce stomatologicznej.',
        image: zespol6,
        description: `Wykonuje zabiegi z zakresu leczenia kanałowego pierwotnego oraz powtórnego Motywację do pracy czerpie z satysfakcjonujących efektów leczenia. Napędza ją wdzięczność pacjentów za przywrócenie pięknego i zdrowego uśmiechu. Wciąż głodna wiedzy, stawiająca na rozwój, szukająca nowinek w branży, uczestnicząc w licznych kursach i szkoleniach zdobywa kolejne certyfikaty i poszerza horyzonty. Z pasją wykonuje swoją pracę. Panią doktor cechuje precyzja i wrażliwość na potrzeby pacjenta. Dokłada wszelkich starań, aby wizyta przebiegała w warunkach komfortowych, bez bólu i strachu.
        `,
      },
      {
        name: 'NADIA - Lekarz Dentysta z wieloletnim doświadczeniem w endodoncji i estetyce stomatologicznej.',
        image: zespol7,
        description: `Wykonuje zabiegi z zakresu leczenia kanałowego pierwotnego oraz powtórnego. Do każdego Pacjenta podchodzi indywidualnie. Stara się by leczenie które prowadzi przebiegało w miłej atmosferze. Jej celem jest spełnianie marzeń o pięknym i zdrowym uśmiechu. Wykazuje się niezwykłą empatią, dzięki czemu bez problemu nawiązuje kontakt z każdym Pacjentem. Praca jest dla niej wielką pasją, dlatego stale podnosi swoje kompetencje i pogłębia wiedzę, biorąc udział w licznych sympozjach i szkoleniach.
        `,
      },
      {
        name: 'ALEKSANDER - Lekarz Dentysta, zajmuje się stomatologią zachowawczą i protetyką.',
        image: zespol8,
        description: `W codziennej pracy zajmuje się tworzeniem pięknego uśmiechu, wykorzystując mało inwazyjne i bezbolesne metody. Zawsze skupia się na odpowiednim planowaniu leczenia w taki sposób, aby uzyskać jak najlepsze efekty nie tylko estetyczne, ale i funkcjonalne. Przeprowadza całkowite rekonstrukcje zgryzu, w oparciu o dokładną analizę i planowanie chirurgiczno – protetyczne. Stale poszerza swoją wiedzę i doświadczenie w zakresie skomplikowanych zabiegów chirurgicznych oraz i bierze udział w licznych szkoleniach i konferencjach z zakresu stomatologii zachowawczej i protetyki.
        `,
      },
  ];

  return (
    <div className="container-fluid my-5">
      <h2 className="text-center galeria">Zespół</h2>
      <div className="row mt-3">
        {teamMembers.map((member, index) => (
          <TeamMember key={index} {...member} />
        ))}
      </div>
    </div>
  );
};

export default TeamSection;
