import { BrowserRouter as Router, Link, Routes, Route } from "react-router-dom";
import Home from "./components/home/Home";
import NotFound from "./components/404/NotFound";
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from "./logo.png"
import Footer from "./components/footer/Footer";
import './App.css';
import "bootstrap/js/src/collapse.js";
import GalleryComponent from "./components/gallery/Gallery";
import { useState } from "react";
import ContactComponent from "./components/contact/contact";
import ServicesComponent from "./components/services/Services"
import TeamSection from "./components/team/teammembers";
import Radiologic from "./components/radiologic/radiologic";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";



function App() {

  const [isNavExpanded, setIsNavExpanded] = useState(false);

  
  const toggleNav = () => {
      setIsNavExpanded(!isNavExpanded);
  };

  const collapseNav = () => {
      setIsNavExpanded(false);
  };
  return (
    <Router>
    <nav class="navbar navbar-expand-lg bg-body-white nav-border">
<div class="container-fluid">
<a className="navbar-brand" href="/">
          <img src={logo} alt="ADEEB CLINIC" className="d-inline-block align-top img-fluid" style={{ maxWidth: '150px', height: 'auto' }} />
        </a>
        <button className="navbar-toggler custom-toggler " type="button" 
                            data-bs-toggle="collapse" 
                            aria-controls="navbarNavAltMarkup" 
                            aria-expanded={isNavExpanded} 
                            aria-label="Toggle navigation"
                            onClick={toggleNav}>
                        <span className="navbar-toggler-icon "></span>
                    </button>
  <div className={`collapse navbar-collapse ${isNavExpanded ? "show" : ""}`} id="navbarNavAltMarkup">
    <Link className="nav-link mx-5 mt-5" to="/" onClick={collapseNav}>Home</Link>
    <Link className="nav-link mx-5 mt-5" to="/uslugi" onClick={collapseNav}>Usługi</Link>
    <Link className="nav-link mx-5 mt-5" to="/galeria" onClick={collapseNav}>Galeria</Link>
    <Link className="nav-link mx-5 mt-5" to="/zespol" onClick={collapseNav}>Zespół</Link>
    <Link className="nav-link mx-5 mt-5" to="/kontakt" onClick={collapseNav}>Kontakt</Link>
   </div>
  </div>
</nav>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/uslugi" element={<ServicesComponent />} />
        <Route path="/galeria" element={<GalleryComponent />} />
        <Route path="/zespol" element={<TeamSection />} />
        <Route path="/kontakt" element={<ContactComponent/>} />
        <Route path="/ochrona-radiologiczna" element={<Radiologic/>} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;