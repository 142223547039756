import React from 'react';
import map from './map.png'
import useDocumentTitle from '../../hooks/title';

const ContactComponent = () => {
    useDocumentTitle("Kontakt")
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-4">
          <h3 className='galeria'>ADRES</h3>
          <p>ul.Legionów Polskich 73/1</p>
          <p>41-300 Dąbrowa Górnicza</p>
          <h3 className='galeria mt-5'>KONTAKT</h3>
          <p><strong>Telefon:</strong> 32 2600121</p>
          <p><strong>Email:</strong> adeebclinic1@gmail.com</p>
        </div>
        <div className="col-md-8">
          <a href='https://www.google.com/maps/place/Stomatologia+Adeeb+Clinic+Wahab+Adeeb/@50.3184743,19.1798283,17z/data=!3m1!4b1!4m6!3m5!1s0x4716dbb1d070ace5:0x4c226faed015fa27!8m2!3d50.3184743!4d19.1824032!16s%2Fg%2F11ssbvrgd0?entry=ttu' target='blank'><img src={map} alt="map" style={{width: '100%'}} /> </a>
        </div>
      </div>
    </div>
  );
};

export default ContactComponent;