import foto1 from './Galeria 1.png'
import foto2 from './Galeria 2.png'
import foto3 from './Galeria 3.png'
import foto4 from './Galeria 4.png'
import foto5 from './Galeria 5.png'
import foto6 from './Galeria 6.png'
import foto7 from './galeria7.jpg'
import useDocumentTitle from '../../hooks/title'





const Gallery = () => {
  useDocumentTitle("Galeria")
  return (
    <div className="container">
      <h2 className='text-center mt-5 galeria'>Galeria</h2>
      <div className="row">
        <div className="col-md-6 mt-5">
          <div className="img-fluid">
            <img src={foto1} alt="Gallery" style={{width: '100%'}} />
          </div>
        </div>
        <div className="col-md-6 mt-5">
          <div className="img-fluid">
            <img src={foto2} alt="Gallery" style={{width: '100%'}} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mt-5">
          <div className="img-fluid">
            <img src={foto3} alt="Gallery" style={{width: '100%'}} />
          </div>
        </div>
        <div className="col-md-6 mt-5">
          <div className="img-fluid">
            <img src={foto4} alt="Gallery" style={{width: '100%'}} />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mt-5">
          <div className="img-fluid">
            <img src={foto5} alt="Gallery" style={{width: '100%'}} />
          </div>
        </div>
        <div className="col-md-6 mt-5">
          <div className="img-fluid">
            <img src={foto6} alt="Gallery" style={{width: '100%'}} />
          </div>
        </div>
        <div className="col-md-12 mt-5">
          <div className="img-fluid">
            <img src={foto7} alt="Gallery" style={{width: '100%'}} />
          </div>
        </div>
      </div>
      {/* Repeat the row for more images */}
    </div>
  );
};

export default Gallery;
