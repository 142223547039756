import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import radme from './logo-radmed.png'
import meditrans from './meditrans-logo-1688532776.jpg'
import insvig from './invis_logo_black_no_res.svg'
import orthero from './orthero-logo-300_80.png'
import pzu from './pzu3.png'
import dentalday from './dentaldeay.png'
import nfz from './OIP.jpg'
import gebizz from './gebissklep.png'
import mediradt from './logo.webp'
import marku from './logo_1_big.png'
import marodent from './logo_morrodent.jpg'
import esden from './esden20.png'

const firms = [
    { id: 1, logo: insvig },
    { id: 2, logo:  orthero },
    { id: 3, logo: pzu  },
    { id: 4, logo: dentalday  },
    { id: 5, logo: nfz },
    { id: 6, logo: gebizz },
    { id: 7, logo: mediradt },
    { id: 8, logo: marku},
    { id: 9, logo: marodent},
    { id: 10, logo: radme},
    { id: 11, logo: meditrans},
    { id: 12, logo: esden},
    // Dodaj więcej firm, jeśli potrzebujesz
];

const FirmsCarousel = () => {
    // Podziel firmy na grupy po trzy
    const groupedFirms = [];
    for (let i = 0; i < firms.length; i += 3) {
        groupedFirms.push(firms.slice(i, i + 3));
    }

    return (
        <Carousel
            showThumbs={false}
            showStatus={false}
            showIndicators={true}
            autoPlay={false}
            showArrows={true}
            infiniteLoop={true}
        >
            {groupedFirms.map((group, index) => (
                <div key={index} className="container p-1">
                    <div className="row d-flex">
                        {group.map(firm => (
                            <div key={firm.id} className="col-md-4 p-5">
                                <div className="card karta2 d-flex flex-column">
                                    <div className="card-body d-flex align-items-center justify-content-center">
                                    <img src={firm.logo} alt={`Logo firmy ${firm.id}`} style={{ "width" : "50%"}} />

                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </Carousel>
    );
};

export default FirmsCarousel;
