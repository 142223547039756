
const InformationSection = () => {
  return (
    <div className="information-section py-3">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            
              <h3 className="text-center mb-3">WARTO WIEDZIEĆ</h3>
              <p className="text-center mb-3" >Wszyscy pacjenci przyjmowani są również bez zapisu w dniu zgłoszenia się do Adeeb Clinic.</p>
              <p className="text-center mb-3">Jeśli w tej chwili zmagasz się z jakąś dolegliwością – pamiętaj, że tylko bezpośrednia konsultacja 
              z lekarzem dentystą pozwoli Ci dowiedzieć się w czym tkwi Twój obecny problem i jak mu zaradzić.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InformationSection;
